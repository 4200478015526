import React from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import Views from "./Views";
import {useSelectorGroup} from "./redux/selectors";
import YandexMetrika from "./common/analytics/YandexMetrika";
import VKAds from "./common/analytics/VKAds";
import BaseInit from "./common/BaseInit";

const App = () => {
    const group = useSelectorGroup() ?? {};
    return (
        <>
            <BaseInit/>
            <div id="vk_api_transport"/>
            {group.yaMetrika && <YandexMetrika counter={+group.yaMetrika}/>}
            {group.vkAdsPixel && <VKAds counter={+group.vkAdsPixel}/>}
            <Views/>
        </>
    );
}

export default App;
