import React from 'react';
import bridge from "@vkontakte/vk-bridge";
import {useDispatch} from "react-redux";
import {hidePopout, showPopout, showSnackbar, toggleLoading} from "../../../../redux/root/rootReducer";
import {fireEventClick, getSearchObject} from "../../../../common/helpers/functions";
import {subscriptionsAPI} from "../../../../api/subscriptions/subscriptionsAPI";
import SubscriptionAlert from "../components/SubscriptionAlert";
import {hit} from "../../../../common/helpers/analytics";
import {dropCache} from "../../../../redux/parts/subscriptionsReducer";

export const useToggleSubscribe = (hashObject) => {
    const dispatch = useDispatch();

    return async (item) => {
        dispatch(toggleLoading(true));
        const search = getSearchObject();

        let phone, email = '';

        // если пользователь подписывается, то проверяем разрешение на отправку
        if (!item.isSubscribed) {
            try {
                await bridge.send('VKWebAppAllowMessagesFromGroup', {group_id: +search.vk_group_id});
            } catch (e) {
                dispatch(toggleLoading(false));
                return;
            }

            if (item.request_phone) {
                try {
                    const data = await bridge.send('VKWebAppGetPhoneNumber');
                    phone = data.phone_number;
                } catch (e) {

                }
            }

            if (item.request_email) {
                try {
                    const data = await bridge.send('VKWebAppGetEmail');
                    email = data.email;
                } catch (e) {

                }
            }
        }

        const data = {
            ...hashObject,
            source: hashObject.s ? 2 : 1,
            id: item.id,
            contact_phone: phone,
            contact_email: email,
        };

        try {
            const res = await subscriptionsAPI.toggleSubscribe(data);
            item = res.item;
        } catch (e) {
            dispatch(toggleLoading(false));
            dispatch(showSnackbar({text: e.text}))
            return;
        }

        const event = item.isSubscribed ? 'subscribe' : 'unsubscribe';
        hit(event);
        hit(event + `_${item._id.$oid}`);

        // VKR запросили отправку в трекер при подписке с определенным уникальным ID
        if (item.isSubscribed) {
            const search = getSearchObject();
            bridge.send('VKWebAppTrackEvent', {
                event_name: `subscribe`,
                user_id: search.vk_user_id,
            }).then((data) => {
                if (data.result) {
                    // Данные отправлены в MyTracker
                    console.log('successfully tracked', data)
                }
            }).catch((error) => {
                // Ошибка
                console.log(error);
            });
        }

        dispatch(toggleLoading(false));
        dispatch(dropCache());
        const redirectUrl = defineRedirectUrl(item);

        if (item.notification !== undefined && !!item.notification === true) {
            if (item.isSubscribed) {
                dispatch(showSubPopout(
                    item.title_on_sub_modal ? item.title_on_sub_modal : 'Вы успешно подписались',
                    item.text_on_sub_modal,
                    'green',
                    item.text_btn_on_sub_modal,
                    redirectUrl
                ));
            } else {
                dispatch(showSubPopout(
                    item.title_on_unsub_modal ? item.title_on_unsub_modal : 'Вы отписались от рассылки',
                    item.text_on_unsub_modal,
                    'red',
                    item.text_btn_on_unsub_modal,
                    redirectUrl
                ));
            }
        } else {
            if (redirectUrl) {
                fireEventClick(redirectUrl);
            }
        }

        return item;
    };
}

const defineRedirectUrl = (item) => {
    let redirectUrl = item.isSubscribed ? item.redirect_url_subscription : item.redirect_url_unsubscribe;
    if (!redirectUrl) return null;
    const matches = redirectUrl.toString().match(/^https?:\/\//);
    return matches === null ? 'https://' + redirectUrl : redirectUrl;
}

const showSubPopout = (title, message = '', icon = null, okText = 'Продолжить', redirectUrl = null) => {
    return (dispatch) => {
        const onClose = () => {
            if (redirectUrl) {
                fireEventClick(redirectUrl);
                return;
            }
            dispatch(hidePopout());
        }

        dispatch(showPopout(
            <SubscriptionAlert
                title={title}
                message={message}
                icon={icon}
                okText={okText ? okText : 'Продолжить'}
                onClose={onClose}
                redirectUrl={redirectUrl}
            />
        ));
    }
}