import {removeSpanFromField, unescapeHtml} from "../../../common/helpers/functions";

const mapTypeToText = new Map()
    .set("button", "Кнопка")
    .set("title", "Заголовок")
    .set("title_url", "Ссылка заголовка")
    .set("descr", "Описание")
    .set("text", "Подробное описание")
    .set("icon_id", "Иконка");

class WidgetsCodeService {
    errors = {
        status: "ok",
        errno: []
    }
    appState;
    widgetState;
    widgetListState;

    constructor({appState, widgetState, widgetListState}) {
        this.appState = appState;
        this.widgetState = widgetState;
        this.widgetListState = widgetListState;
    }

    async genCode(type) {
        let code = this.checkByType(type);
        this.getErrorStatus();

        if (this.errors.status !== "ok") {
            return this.errors.errno;
        }

        const title = this.getEscapeQuotes(unescapeHtml(this.widgetState.title));
        const footer = this.getEscapeQuotes(unescapeHtml(this.widgetState.footerText));

        return `
        var user = API.users.get({user_ids: Args.uid, fields: 'relation, sex, city, country, first_name_gen, first_name_dat, first_name_acc, first_name_ins, first_name_abl, last_name_gen, last_name_dat, last_name_acc, last_name_ins, last_name_abl'})[0];
        var isMember = API.groups.isMember({group_id: ${this.appState.searchObject.vk_group_id}, user_id: Args.uid });
        
        var relation = {
            '1' : {
                '1' : 'Не замужем',
                '2' : 'Встречаюсь',
                '3' : 'Помолвлена',
                '4' : 'Замужем',
                '5' : 'Всё сложно',
                '6' : 'В активном поиске',
                '7' : 'Влюблена',
                '8' : 'В гражданском браке',
            },
            '2' : {
                '1' : 'Не женат',
                '2' : 'Встречаюсь',
                '3' : 'Помолвлен',
                '4' : 'Женат',
                '5' : 'Всё сложно',
                '6' : 'В активном поиске',
                '7' : 'Влюблён',
                '8' : 'В гражданском браке',
            }
        };
        
    return {
            "title": "${title}",
            ${this.getStr("title_url", this.widgetState.linkTitle)}
            ${code},
            ${this.getStr("more", footer)}
            ${this.getStr("more_url", this.widgetState.footerLink)}
        };`;
    };

    checkGeneralWidgetData() {
        if (this.widgetState.title.length > 100) {
            this.errors.errno.push("Длина заголовка должна быть не более 100 символов");
        }
        if (this.widgetState.footerText.length > 100) {
            this.errors.errno.push("Длина футера должна быть не более 100 символов");
        }
        if (this.widgetState.linkTitle.length !== 0 && !this.isCorrectVkLink(this.widgetState.linkTitle)) {
            this.errors.errno.push("Некорректная ссылка главного заголовка");
        }
        if (!this.isCorrectVkLink(this.widgetState.footerLink) && this.widgetState.footerText.length !== 0)
            this.errors.errno.push("Некорректная ссылка футера");
    };

    checkDataList() {
        this.checkGeneralWidgetData();
        const items = this.widgetListState.rows;
        this.checkCorrectFieldCount(items, ["icon_id", "text", "button"]);

        items.map((item, index) => {

            const title = unescapeHtml(item.title);
            const descr = unescapeHtml(item.descr);
            const address = unescapeHtml(item.address);
            const time = unescapeHtml(item.time);
            const text = unescapeHtml(item.text);
            const button_text = unescapeHtml(item.button);

            if (title === "") {
                this.errors.errno.push(`Элемент списка №${index + 1}: Отсутствует заголовок`);
            }

            if (title.length > 100) {
                this.errors.errno.push(
                    `Элемент списка №${index + 1}: Длина заголовка должна быть не более 100 символов
            Введено символов: ${title.length}`);
            }
            if (item.title_url.length !== 0) {
                if (!this.isCorrectVkLink(item.title_url)) {
                    this.errors.errno.push(
                        `Элемент списка №${index + 1}: Некорректная ссылка в заголовке`);
                }
            }
            if (descr.length > 100) {
                this.errors.errno.push(
                    `Элемент списка №${index + 1}: Длина краткого описания должна быть не более 100 символов
            Введено символов: ${descr.length}`);
            }
            if (address.length > 100) {
                this.errors.errno.push(
                    `Элемент списка №${index + 1}: Длина адреса должна быть не более 100 символов
            Введено символов: ${address.length}`);
            }
            if (time.length > 100) {
                this.errors.errno.push(
                    `Элемент списка №${index + 1}: Длина времени работы должна быть не более 100 символов.
                         Введено символов: ${time.length}`);
            }
            if (text.length > 300) {
                this.errors.errno.push(
                    `Элемент списка №${index + 1}: Длина подробного описания должна быть не более 300 символов
            Введено символов: ${text.length}`);
            }
            if (button_text.length !== 0 && !this.isCorrectVkLink(item.button_url))
                this.errors.errno.push(`Элемент списка №${index + 1}: Некорректная ссылка кнопки`);
            return 0;
        });


        let resArray = [];
        for (let i = 0; i < items.length; i++) {
            const elem = items[i];

            let resElem = {};
            resElem = {
                title: unescapeHtml(elem.title),
                button: unescapeHtml(elem.button),
                button_url: elem.button_url,
                icon_id: (elem.icon_type === "user_avatar" ? 'id%userid%' : elem.icon_id),
                descr: unescapeHtml(elem.descr),
                address: unescapeHtml(elem.address),
                time: unescapeHtml(elem.time),
                text: unescapeHtml(elem.text)
            };

            if (elem.title_url.length !== 0) {
                resElem['url'] = elem.title_url;
            }
            resArray.push(resElem);
        }
        return JSON.stringify(resArray);
    };

    checkDataText() {
        this.checkGeneralWidgetData();

        const text = unescapeHtml(this.widgetState.text);
        const description = unescapeHtml(this.widgetState.description);

        if (removeSpanFromField(text).length > 200) {
            this.errors.errno.push(`Текст не должен превышать длину в 200 символов.
         Введено символов: ${text.length}`);
        }
        if (removeSpanFromField(description).length > 200) {
            this.errors.errno.push(`Описание не должно превышать длину в 200 символов.
         Введено символов: ${description.length}`);
        }
    };

    checkDataTiles() {
        this.checkGeneralWidgetData();
        this.checkCorrectFieldCount(this.widgetListState.rows, ["title", "button", "title_url"]);

        const tiles = this.widgetListState.rows;
        let iconTypes = [];

        tiles.map((item, index) => {
            const descr = unescapeHtml(item.descr);
            const button_text = unescapeHtml(item.button);

            item.icon_id && iconTypes.push(+item.icon_id[0]);
            if (descr.length > 50) {
                this.errors.errno.push(
                    `Плитка №${index + 1}: Длина описания должна быть не более 50 символов.
                Введено символов: ${descr.length}`);
            }
            if (item.icon_id === null) {
                this.errors.errno.push(
                    `Плитка №${index + 1}: Отсутствует изображение (обязательное поле).`
                );
            }
            if (button_text.length > 50) {
                this.errors.errno.push(
                    `Плитка №${index + 1}: Длина текста кнопки должна быть не более 50 символов.
                Введено символов: ${button_text.length}`);
            }
            if (!this.isCorrectVkLink(item.button_url)) {
                this.errors.errno.push(
                    `Плитка №${index + 1}: Некорректная ссылка в кнопке`);
            }
            if (!this.isCorrectVkLink(item.title_url)) {
                this.errors.errno.push(
                    `Плитка №${index + 1}: Некорректная ссылка в заголовке`);
            }
            return 0;
        });

        if ([...new Set(iconTypes)].length !== 1) {
            this.errors.errno.push(`Изображения должны быть одного типа`);
        }

        if (tiles.length < 3) {
            this.errors.errno.push(`Количество плиток должно быть не меньше трех`);
        }

        let resArray = [];
        for (let i = 0; i < tiles.length; i++) {
            const elem = tiles[i];

            let resElem = {
                title: unescapeHtml(elem.title),
                link: unescapeHtml(elem.button),
                link_url: elem.button_url,
                icon_id: (elem.icon_type === "user_avatar" ? 'id%userid%' : elem.icon_id),
                descr: unescapeHtml(elem.descr)
            };

            if (elem.title_url.length !== 0) {
                resElem['url'] = elem.title_url;
            }

            resArray.push(resElem);
        }
        return JSON.stringify(resArray);
    };

    checkDataCoverList() {
        this.checkGeneralWidgetData();
        this.checkCorrectFieldCount(this.widgetListState.rows, ["descr", "button"]);
        const items = JSON.parse(removeSpanFromField(JSON.stringify(this.widgetListState.rows)));

        items.map((item, index) => {

            const title = unescapeHtml((item.title));
            const descr = unescapeHtml((item.descr));

            if (item.icon_id === null) {
                this.errors.errno.push(`Элемент списка №${index + 1}: Отсутствует изображение (обязательное поле)`);
            }
            if (item.title === "") {
                this.errors.errno.push(`Элемент списка №${index + 1}: Отсутствует заголовок`);
            }
            if (title.length > 100) {
                this.errors.errno.push(
                    `Элемент №${index + 1}: Длина заголовка должна быть не более 100 символов
            Введено символов: ${title.length}`);
            }
            if (item.title_url.length !== 0 && !this.isCorrectVkLink(item.title_url)) {
                this.errors.errno.push(
                    `Элемент №${index + 1}: Некорректная ссылка в заголовке`);
            }
            if (descr.length > 100) {
                this.errors.errno.push(
                    `Элемент №${index + 1}: Длина краткого описания должна быть не более 100 символов
            Введено символов: ${descr.length}`);
            }
            return 0;
        });

        const codeCoverList = items.map((elem) => {
            let coverListObj = {};
            coverListObj.title = unescapeHtml(elem.title);
            coverListObj.button = unescapeHtml(elem.button);
            coverListObj.button_url = elem.button_url;
            coverListObj.cover_id = elem.icon_id;
            if (elem.title_url.length !== 0) {
                coverListObj.url = elem.title_url;
            }
            coverListObj.descr = unescapeHtml(elem.descr);
            coverListObj.text = unescapeHtml(elem.text);
            return coverListObj;
        });

        return JSON.stringify(codeCoverList)
    };

    checkByType(type) {
        let codeList, codeCoverList, codeText, codeTiles;
        switch (type) {
            case 'text':
                this.checkDataText();
                codeText = `
                "text": "${removeSpanFromField(this.getEscapeQuotes(unescapeHtml(this.escapeLines(this.widgetState.text))))}",
                "descr": "${removeSpanFromField(this.getEscapeQuotes(unescapeHtml(this.escapeLines(this.widgetState.description))))}"
            `;
                break;
            case 'compact_list':
            case 'list':
                let codeList_temp = this.checkDataList();
                codeList = `
                "rows": ${removeSpanFromField(codeList_temp)}
            `;
                break;
            case 'cover_list':
                let codeCoverList_temp = this.checkDataCoverList();
                codeCoverList = `
                "rows": ${removeSpanFromField(codeCoverList_temp)}
            `;
                break;
            case 'tiles':
            case 'tiles_square':
                let codeTiles_temp = this.checkDataTiles();
                codeTiles = `
                "tiles": ${removeSpanFromField(codeTiles_temp)}
            `;
                break;
            default:
                this.errors.errno.push("Не удалось сохранить виджет.");
        }

        const mapCode = new Map()
            .set('text', codeText)
            .set('list', codeList)
            .set('cover_list', codeCoverList)
            .set('compact_list', codeList)
            .set('tiles', codeTiles)
            .set('tiles_square', codeTiles);

        return mapCode.get(type);
    };

    getStr(text, linkTitle) {
        if (linkTitle.length !== 0) {
            return `"${this.getEscapeQuotes(unescapeHtml(text))}" : "${linkTitle}",`
        }
        return "";
    };

    getErrorStatus() {
        if (this.errors.errno.length !== 0) {
            this.errors.status = "error";
        } else {
            this.errors.status = "ok";
        }
    };

    getEscapeQuotes(str) {
        return str
            .replace(/"/g, '\\"')
            .replace(/'/g, "'");

    };

    escapeLines(str) {
        return str.replaceAll("\r\n", "\\n").replaceAll("\r", "\\n")
    }

    isCorrectVkLink(link) {
        if (link.indexOf("vk.com") !== -1 && link.indexOf("vk.com") < 10) {
            return true
        }
        if (link.indexOf("vk.me") !== -1 && link.indexOf("vk.me") < 10) {
            return true
        }
        return link.indexOf("vk.cc/") !== -1 && link.indexOf("vk.cc/") < 10;
    };

    checkCorrectFieldCount(arrayItems, fields) {
        const tempArr = fields.map(() => {
            return 0;
        });
        arrayItems.map((item) => {
            fields.map((field, fieldKey) => {
                if (item[field] === "" || item[field] === null) {
                    tempArr[fieldKey]++;
                }
                return 0;
            });
            return 0;
        });
        fields.map((elem, key) => {
            if (arrayItems.length !== tempArr[key] && tempArr[key] !== 0) {
                this.errors.errno.push(`Элемент "${mapTypeToText.get(elem)}" 
            должен присутствовать в каждом элементе или не быть не в одном из них.`)
            }
            return 0;
        })
    };
}

export default WidgetsCodeService;