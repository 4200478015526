import bridge from "@vkontakte/vk-bridge";
import THConfig from '../../config/THConfig';
import {getSearchObject} from "./functions";

export const resizeWindowToDefault = async () => {
    return resizeWindow(THConfig.app.height, THConfig.app.width)
}

export const resizeWindow = async (height = null, width = null) => {
    height = height ? height : document.body.scrollHeight;
    width = width ? width : document.body.offsetWidth;
    return bridge.send("VKWebAppResizeWindow", {"width": +width, "height": +height});
}

export const showWidgetPreviewBox = async (code, type) => {
    return await bridge.send("VKWebAppShowCommunityWidgetPreviewBox", {
        "group_id": +getSearchObject().vk_group_id,
        "type": type === 'tiles_square' ? 'tiles' : type,
        "code": code ? code : "return false;"
    });
};

export const getWidgetToken = async() => {
    const searchObject = getSearchObject();
    return await bridge.send("VKWebAppGetCommunityToken", {
        "app_id": +searchObject.vk_app_id,
        "group_id": +searchObject.vk_group_id,
        "scope": "app_widget"
    });
}

export const getIconById = async (type, id, token) => {
    if (type === "user_avatar" || type === "some_user_avatar") {
        const result = await bridge.send("VKWebAppCallAPIMethod", {
            "method": "users.get",
            "params": {
                "user_ids": id,
                "fields": "photo_id, photo_50, photo_200",
                "v": "5.124",
                "access_token": token
            },
        });
        return {
            icon_id: "id" + id,
            icon_url_list: result.response[0].photo_50,
            icon_url_tiles: result.response[0].photo_200,
        };
    }

    const result = await bridge.send("VKWebAppCallAPIMethod", {
        "method": "groups.getById",
        "params": {
            "group_id": id,
            "v": "5.124",
            "access_token": token
        },
    });
    return {
        icon_id: "id-" + id,
        icon_url_list: result.response[0].photo_50,
        icon_url_tiles: result.response[0].photo_200,
    };
}

export const copyText = async (text) => {
    return await bridge.send("VKWebAppCopyText", {"text": text});
}