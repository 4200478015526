import ym from 'react-yandex-metrika';
import store from "../../redux/store";
import {appAPI} from "../../api/app/appAPI";

export const hit = (event) => {
    const {vkPixel, yaMetrika, vkAdsPixel} = store.getState().app.group ?? {};

    if (vkPixel && window.VK) {
        console.log('VK.Pixel', vkPixel, event);
        window.VK.Retargeting.Init(vkPixel);
        window.VK.Retargeting.Hit();
        window.VK.Retargeting.Event(event);

        let goal = event;
        if (goal === 'view') {
            goal = 'page_view'; // https://vk.com/faq16578
        }
        window.VK.Goal(goal);
    }

    if (yaMetrika) {
        console.log('Ya.Metrika', yaMetrika, event);
        ym('reachGoal', event);
    }

    if (vkAdsPixel && window._tmr !== undefined) {
        console.log('VKAds.Pixel', vkAdsPixel, event);
        window._tmr.push({ type: 'reachGoal', id: vkAdsPixel, goal: event});
    }

    appAPI.hit(event);
}

export const asyncInitVKOpenAPI = () => {
    window.vkAsyncInit = function () {
        window.VK.init({
            apiId: 7070938
        });
    };

    setTimeout(function () {
        var el = document.createElement("script");
        el.type = "text/javascript";
        el.src = "https://vk.com/js/api/openapi.js?169";
        el.async = true;
        el.onerror = (e) => {console.log('vk api error', e)}
        document.getElementById("vk_api_transport").appendChild(el);
    }, 0);
}