import React from "react";
import {SimpleCell, Spacing} from "@vkontakte/vkui";
import {PAGES} from "../../../../config/routes";
import {
    Icon28GraphOutline,
    Icon28HelpCircleOutline,
    Icon28LightbulbOutline,
    Icon28LinkCircleOutline,
    Icon28SettingsOutline,
    Icon28UserCardOutline
} from "@vkontakte/icons";
import {useDispatch} from "react-redux";
import THConfig from "../../../../config/THConfig";
import {fireEventClick} from "../../../../common/helpers/functions";
import {showAlert} from "../../../../redux/root/rootReducer";
import useIsDesktop from "../../../../common/hooks/useIsDesktop";
import {useRouter} from "@happysanta/router";
import useIgnoreChangeFragmentOnAndroid from "../../../../common/hooks/useIgnoreChangeFragmentOnAndroid";

const AdminNavigation = props => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isDesktop = useIsDesktop();
    const ignoreChangeFragmentAndroid = useIgnoreChangeFragmentOnAndroid();

    const onWidgetsClick = () => {
        if (isDesktop) {
            router.pushPage(PAGES.WIDGETS);
        } else {
            dispatch(showAlert({message: 'В связи с особенностями отображения виджетов, на мобильных устройствах их настраивать неудобно. ' +
                    'Откройте приложение с компьютера'}))
        }
    };

    const openUrl = (url) => {
        ignoreChangeFragmentAndroid();
        fireEventClick(url, '_blank')
    }

    return (
        <>
            <SimpleCell
                onClick={onWidgetsClick}
                expandable
                before={<Icon28UserCardOutline/>}
            >
                Виджеты
            </SimpleCell>
            <SimpleCell
                onClick={() => router.pushPage(PAGES.SETTINGS_ANALYTICS)}
                expandable
                before={<Icon28GraphOutline/>}
            >
                Аналитика
            </SimpleCell>
            <SimpleCell
                onClick={() => router.pushPage(PAGES.SETTINGS)}
                expandable
                before={<Icon28SettingsOutline/>}
            >
                Настройки
            </SimpleCell>
            <Spacing size={24} separator={true}/>
            <SimpleCell
                onClick={() => openUrl(THConfig.system.supportUrl)}
                before={<Icon28HelpCircleOutline/>}
            >
                Техническая поддержка
            </SimpleCell>
            <SimpleCell
                onClick={() => openUrl(THConfig.system.docsUrl)}
                before={<Icon28LightbulbOutline/>}
            >
                Документация
            </SimpleCell>
            <SimpleCell
                onClick={() => openUrl(THConfig.system.url)}
                before={<Icon28LinkCircleOutline/>}
            >
                Полная версия сервиса (чат-боты и рассылки)
            </SimpleCell>
        </>
    )
}

export default AdminNavigation;