import React, {useEffect} from "react";

const VKAds = ({counter}) => {
    useEffect(() => {
        const script = document.createElement('script');
        const noscript = document.createElement('noscript');
        script.innerHTML = `var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({id: "${counter}", type: "pageView", start: (new Date()).getTime()});
        (function (d, w, id) {
          if (d.getElementById(id)) return;
          var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
          ts.src = "https://top-fwz1.mail.ru/js/code.js";
          var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
          if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
        })(document, window, "tmr-code");`;

        noscript.innerHTML = `<noscript><div><img src="https://top-fwz1.mail.ru/counter?id=${counter};js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div></noscript>`

        document.body.appendChild(script);
        document.body.appendChild(noscript);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(noscript);
        }
    }, [counter]);

    return <></>
}

export default VKAds;
