import {hidePopout, setActiveModal, showPopout, showSnackbar} from "../root/rootReducer";
import React from "react";
import PopoutErrorWidget from "../../common/components/popouts/PopoutErrorWidget";
import {setWidgetsAction} from "./widgetListReducer";
import {Alert} from "@vkontakte/vkui";
import {deleteWidgetFunction} from "../../views/widgets/common/functions";
import {widgetsAPI} from "../../api/widgets/widgetsAPI";
import WidgetsService from "../../common/logic/WidgetsService";
import {showWidgetPreviewBox} from "../../common/helpers/bridge";
import {AlertText, AlertTitle} from "../../common/components/Alerts/Components";
import WidgetsCodeService from "../../views/widgets/common/WidgetsCodeService";
import {router} from "../../router";

const SET_ACTIVE_WIDGET_ID = 'SET_ACTIVE_WIDGET_ID';
const SET_CURRENT = 'SET_CURRENT';
const SET_TYPE = 'SET_TYPE';
const SET_ID = 'SET_ID';
const SET_NAME = 'SET_NAME';
const SET_TITLE = 'SET_TITLE';
const SET_TITLE_COUNT = 'SET_TITLE_COUNT';
const SET_LINK_TITLE = 'SET_LINK_TITLE';
const SET_TEXT = 'SET_TEXT';
const SET_DESCRIPTION = 'SET_DESCRIPTION';
const SET_FOOTER = 'SET_FOOTER';
const SET_TEXT_WIDGET_TO_DEFAULT = 'SET_TEXT_WIDGET_TO_DEFAULT';
const SET_GENERAL_WIDGET_TO_DEFAULT = 'SET_GENERAL_WIDGET_TO_DEFAULT';
const SET_SAVING_TYPE = 'SET_SAVING_TYPE';
export const SAVING = 'SAVING';
export const PUBLISH = 'PUBLISH';

const initialState = {
    activeWidgetId: null,
    savingType: null,
    current: null,
    type: null,
    id: null,
    name: null,
    title: "Заголовок",
    titleCount: null,
    linkTitle: "vk.com",
    text: "Текст",
    description: "Описание",
    footerText: "Футер",
    footerLink: "vk.com",
};

const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_WIDGET_ID:
            return {
                ...state,
                activeWidgetId: action.id
            }
        case SET_SAVING_TYPE:
            return {
                ...state,
                savingType: action.savingType
            }
        case SET_CURRENT:
            return {
                ...state,
                current: action.widget
            };
        case SET_TYPE:
            return {
                ...state,
                type: action.widgetType
            };
        case SET_ID:
            return {
                ...state,
                id: action.id
            };
        case SET_NAME:
            return {
                ...state,
                name: action.name
            };
        case SET_TITLE:
            return {
                ...state,
                title: action.title
            };
        case SET_TITLE_COUNT:
            return {
                ...state,
                titleCount: action.count
            };
        case SET_LINK_TITLE:
            return {
                ...state,
                linkTitle: action.link,
            };
        case SET_TEXT:
            return {
                ...state,
                text: action.text
            };
        case SET_DESCRIPTION:
            return {
                ...state,
                description: action.description
            };
        case SET_FOOTER:
            return {
                ...state,
                footerText: action.footerText,
                footerLink: action.footerLink
            };
        case SET_TEXT_WIDGET_TO_DEFAULT:
            return {
                ...state,
                text: "Текст",
                description: "Описание"
            };
        case SET_GENERAL_WIDGET_TO_DEFAULT:
            return {
                ...state,
                id: null,
                name: null,
                title: "Заголовок",
                titleCount: null,
                linkTitle: "vk.com",
                footerText: "Футер",
                footerLink: "vk.com"
            };
        default:
            return state;
    }
};
export default widgetReducer;

export const CURRENT_WIDGET = 'CURRENT_WIDGET';
export const TEXT_WIDGET = 'TEXT_WIDGET';
export const LIST_WIDGET = 'LIST_WIDGET';
export const COMPACT_LIST_WIDGET = 'COMPACT_LIST_WIDGET';
export const TILES_WIDGET = 'TILES_WIDGET';
export const TILES_SQUARE_WIDGET = 'TILES_SQUARE_WIDGET';
export const COVER_LIST_WIDGET = 'COVER_LIST_WIDGET';

export const mapWidgetToType = new Map()
    .set(TEXT_WIDGET, 'text')
    .set(LIST_WIDGET, 'list')
    .set(COMPACT_LIST_WIDGET, 'compact_list')
    .set(COVER_LIST_WIDGET, 'cover_list')
    .set(TILES_WIDGET, 'tiles')
    .set(TILES_SQUARE_WIDGET, 'tiles_square');

export const setActiveWidget = (id) => ({type: SET_ACTIVE_WIDGET_ID, id});
export const setCurrent = (widget) => ({type: SET_CURRENT, widget});
export const setType = (widgetType) => ({type: SET_TYPE, widgetType});
export const setId = (id) => ({type: SET_ID, id});
export const setName = (name) => ({type: SET_NAME, name});
export const setTitle = (title) => ({type: SET_TITLE, title});
export const setTitleCount = (count) => ({type: SET_TITLE_COUNT, count});
export const setLinkTitle = (link) => ({type: SET_LINK_TITLE, link});
export const setText = (text) => ({type: SET_TEXT, text});
export const setDescription = (description) => ({type: SET_DESCRIPTION, description});
export const setFooter = (footerText, footerLink) => ({type: SET_FOOTER, footerText, footerLink});
export const setTextWidgetToDefault = () => ({type: SET_TEXT_WIDGET_TO_DEFAULT});
export const setGeneralWidgetToDefault = () => ({type: SET_GENERAL_WIDGET_TO_DEFAULT});
export const setSavingType = (savingType) => ({type: SET_SAVING_TYPE, savingType});

export const publishWidgetSettings = () => {
    return async (dispatch, getState) => {
        const appState = getState().app;
        const widgetState = getState().widget;
        const code = await new WidgetsCodeService({appState, widgetState}).genCode("text");

        if (typeof (code) != 'object') {
            let response = null;
            try {
                response = await widgetsAPI.getVariablesCode(code);
            } catch (e) {
                return;
            }
            try {
                await showWidgetPreviewBox(response.code, 'text');
            } catch (e) {
                console.log('publish widget error', e);
                if (+e.error_data.error_code === 4) return;
                dispatch(showSnackbar({text: e.error_data.error_reason}))
                return;
            }
            try {
                await WidgetsService.saveTextWidgetOnServer(widgetState, appState, false);
            } catch (e) {
                return;
            }
            dispatch(showSnackbar({success: true, text: "Виджет опубликован"}));
            router.popPage();
            dispatch(setActiveWidget(widgetState.id));
            dispatch(setGeneralWidgetToDefault());
            dispatch(setTextWidgetToDefault());
        } else (dispatch(showPopout(PopoutErrorWidget(dispatch, code))));
    }
};

export const saveWidgetSettings = () => {
    return async (dispatch, getState) => {
        const appState = getState().app;
        const widgetState = getState().widget;
        const code = await new WidgetsCodeService({appState, widgetState}).genCode("text");

        if (typeof (code) != 'object') {
            try {
                await WidgetsService.saveTextWidgetOnServer(widgetState, appState, true);
            } catch (e) {
                return;
            }

            router.popPage();
            dispatch(showSnackbar({success: true, text: "Виджет сохранен"}));
            dispatch(setActiveWidget(widgetState.id));
            dispatch(setGeneralWidgetToDefault());
            dispatch(setTextWidgetToDefault());
        } else (dispatch(showPopout(PopoutErrorWidget(dispatch, code))));
    }
};

export const setGeneralWidgetData = (data) => {
    return (dispatch) => {
        dispatch(setId(data._id.$oid));
        dispatch(setName(data.name));
        dispatch(setTitle(data.title));
        dispatch(setTitleCount(data.title_counter));
        dispatch(setLinkTitle(data.title_url));
        dispatch(setFooter(data.more, data.more_url));
    }
};

export const setTextWidgetData = (data) => {
    return (dispatch) => {
        dispatch(setText(data.text));
        dispatch(setDescription(data.descr));
    }
};

export const disableWidget = () => {
    return async (dispatch, getState) => {
        const widgetState = getState().widget;
        try {
            await showWidgetPreviewBox('', "text");
            await widgetsAPI.unplug(widgetState.id);
            const response = await widgetsAPI.getList();
            dispatch(setActiveWidget(null));
            dispatch(showSnackbar({success: true, text: "Виджет отключен"}));
            dispatch(setWidgetsAction(response.widgets));
            dispatch(setActiveModal(null));
        } catch (e) {
            dispatch(hidePopout());
            dispatch(setActiveModal(null));
        }
    }
};

export const deleteWidget = () => {
    return async (dispatch, getState) => {

        const widgetId = getState().widget.current._id.$oid;
        const activeWidget = getState().widget.activeWidgetId;

        dispatch(showPopout(
            <Alert
                actions={[{
                    title: 'Отмена',
                    autoclose: true,
                    mode: 'cancel'
                }, {
                    title: 'Удалить',
                    mode: 'destructive',
                    autoclose: true,
                    action: async () => {
                        let success;
                        if (widgetId === activeWidget) {
                            try {
                                await showWidgetPreviewBox('', "text");
                                success = await deleteWidgetFunction(dispatch, getState, widgetId);
                            } catch (e) {
                                dispatch(hidePopout());
                                dispatch(setActiveModal(null));
                            }
                        } else
                            success = await deleteWidgetFunction(dispatch, getState, widgetId);
                        if (success) {
                            dispatch(setWidgetsAction(success.widgets));
                            dispatch(showSnackbar({
                                success: true,
                                text: "Виджет удален"
                            }));
                        } else {
                            dispatch(showSnackbar({
                                success: false,
                                text: "Произошла ошибка. Виджет не удален"
                            }));
                        }
                    },
                }]}
                onClose={() => {
                    dispatch(hidePopout())
                }}
            >
                <AlertTitle>Подтвердите действие</AlertTitle>
                <AlertText>Удалить виджет?</AlertText>
            </Alert>
        ))
    }
};